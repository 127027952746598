<template>
  <div>

    <div>
      <v-container fluid class="d-flex flex-wrap">
        <div class="pa-2" v-for="(b, i) in banks" v-bind:key="i">
          <v-checkbox  :label="b.name" v-model="b.selected" v-on:change="change"></v-checkbox>
        </div>
      </v-container>
    </div>

  </div>
</template>

<script>

import gql from "graphql-tag";

export default {
  name: "BankCheckgroup",

  props: ['selected'],

  data: function () {
    return {
      dialog: false,
      selected2: [],
      id: null,
      banks: []
    }
  },

  mounted() {
    this.selected2 = this.selected
  },

  apollo: {
    $subscribe: {
      banks: {
        query: gql`
subscription ($country: String!) {
  banks(order_by: {id: desc}, where: {country: {_eq: $country}}) {
    status
    rate
    name
    logo
    id
  }
}
`,

        variables() {
          return {
            country: this.getCountry()
          }
        },


        result({data}) {


          data.banks.forEach(b =>  {
            b.selected =  this.selected2.includes(b.id)
          } )

          this.banks = data.banks;

        },
      },
    },
  },

  methods: {

    getCountry() {
      return this.$store.state.user.country
    },



    change() {
      let a = []
      this.banks.forEach(b=>{

        if (b.selected)
          a.push(b.id)

      })

      this.$emit('change', a)



    }
  }
}
</script>

