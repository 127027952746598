var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _c(
          "v-container",
          { staticClass: "d-flex flex-wrap", attrs: { fluid: "" } },
          _vm._l(_vm.banks, function (b, i) {
            return _c(
              "div",
              { key: i, staticClass: "pa-2" },
              [
                _c("v-checkbox", {
                  attrs: { label: b.name },
                  on: { change: _vm.change },
                  model: {
                    value: b.selected,
                    callback: function ($$v) {
                      _vm.$set(b, "selected", $$v)
                    },
                    expression: "b.selected",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }