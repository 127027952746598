var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Snackbar", { ref: "snackbar" }),
      _c("v-card", { staticClass: "ma-2 pa-2" }, [
        _c("h3", [
          _vm._v(
            "Настройки мерчанта - " + _vm._s(_vm.getBrand()) + " payments"
          ),
        ]),
        _vm._v(" URL платежного виджета "),
        _c("div", { staticClass: "mb-4" }, [
          _c(
            "a",
            { staticClass: "mb-4", attrs: { href: _vm.geturl() + "/?a=2112" } },
            [_vm._v(" " + _vm._s(_vm.geturl()) + " ")]
          ),
        ]),
        _vm._v(" Документация по платежной странице: "),
        _c("br"),
        _c("div", { staticClass: "mb-4" }, [
          _c("a", { attrs: { href: _vm.getUrlDocs } }, [
            _vm._v(_vm._s(_vm.getUrlDocs)),
          ]),
        ]),
        _vm._v(" Ваш api_key"),
        _c("br"),
        _c("div", { staticClass: "mb-4" }, [_vm._v(" " + _vm._s(_vm.token))]),
        _vm._v(" Ваш api_secret_key (токен выплат)"),
        _c("br"),
        _c("div", { staticClass: "mb-4" }, [
          _vm._v(" " + _vm._s(_vm.payoutToken)),
        ]),
        _vm._v(" Токен авторизации в телеграм боте"),
        _c("br"),
        _c("div", { staticClass: "mb-4" }, [_vm._v(" " + _vm._s(_vm.tgtoken))]),
        _vm._v(" Адрес колбэк"),
        _c("br"),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("v-text-field", {
              attrs: {
                label: "url",
                autocomplete: "username",
                outlined: "",
                clearable: "",
              },
              model: {
                value: _vm.callback,
                callback: function ($$v) {
                  _vm.callback = $$v
                },
                expression: "callback",
              },
            }),
            _c(
              "v-btn",
              {
                staticClass: "blue",
                attrs: { dense: "", text: "" },
                on: {
                  click: function ($event) {
                    return _vm.callbackSave()
                  },
                },
              },
              [_vm._v("Сохранить")]
            ),
          ],
          1
        ),
        _vm._v(" Банки "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("BankCheckgroup", {
              attrs: { selected: _vm.banks },
              on: { change: _vm.bankschanged },
            }),
            _c(
              "v-btn",
              {
                staticClass: "blue",
                attrs: { dense: "", text: "" },
                on: {
                  click: function ($event) {
                    return _vm.bankSave()
                  },
                },
              },
              [_vm._v("Сохранить")]
            ),
          ],
          1
        ),
        _vm._v(" Сменить пароль "),
        _c(
          "div",
          { staticClass: "mb-4" },
          [
            _c("v-text-field", {
              attrs: {
                label: "Текущий пароль",
                outlined: "",
                clearable: "",
                autocomplete: "new-password",
                "append-icon": _vm.show2 ? "mdi-eye" : "mdi-eye-off",
                type: _vm.show2 ? "text" : "password",
              },
              on: {
                "click:append": function ($event) {
                  _vm.show2 = !_vm.show2
                },
              },
              model: {
                value: _vm.password,
                callback: function ($$v) {
                  _vm.password = $$v
                },
                expression: "password",
              },
            }),
            _c("v-text-field", {
              attrs: {
                label: "Новый пароль",
                outlined: "",
                clearable: "",
                "append-icon": _vm.show1 ? "mdi-eye" : "mdi-eye-off",
                type: _vm.show1 ? "text" : "password",
              },
              on: {
                "click:append": function ($event) {
                  _vm.show1 = !_vm.show1
                },
              },
              model: {
                value: _vm.newpassword,
                callback: function ($$v) {
                  _vm.newpassword = $$v
                },
                expression: "newpassword",
              },
            }),
            _c(
              "v-btn",
              {
                staticClass: "blue",
                attrs: { dense: "", text: "" },
                on: {
                  click: function ($event) {
                    return _vm.passSave()
                  },
                },
              },
              [_vm._v("Сохранить")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }